import React, { Component } from 'react';
import Header from '../components/Header';
import HeaderInfo from '../components/HeaderInfo';
import About from '../components/About';
import OurServices from '../components/OurServices';
import Portfolio from '../components/Portfolio';
import Contact from '../components/Contact';
import OutCustomers from '../components/OutCustomers';
import OurTechnologies from '../components/OurTechnologies';

export default class Home extends Component{

    componentDidMount(){
        window.addEventListener("scroll", this.onScroll, true);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.onScroll);
    }

    onScroll = () => {
        
        this.removeAllActiveClasses();
        let targetId = this.getActiveClassId();

        if(targetId && targetId.length > 0){
            this.addActiveClassToItem(targetId);
        }
    }

    removeAllActiveClasses = () => {
        let aboutContainer = document.getElementById("nav_about");
        let servicesContainer = document.getElementById("nav_services");
        let portfolioContainer = document.getElementById("nav_portfolio");
        let contactContainer = document.getElementById("nav_contact");

        if(aboutContainer && aboutContainer.classList.contains("active")){
            aboutContainer.classList.remove("active");
        }

        if(servicesContainer && servicesContainer.classList.contains("active")){
            servicesContainer.classList.remove("active");
        }

        if(portfolioContainer && portfolioContainer.classList.contains("active")){
            portfolioContainer.classList.remove("active");
        }

        if(contactContainer && contactContainer.classList.contains("active")){
            contactContainer.classList.remove("active");
        }
    }

    addActiveClassToItem = (item) => {
        let container = document.getElementById(item);

        if(container){
            container.classList.add("active");
        }
    }

    getSectionsHeight = () => {
        let bannerHeight = 0, aboutHeight = 0, servicesHeight = 0, portfolioHeight = 0;

        let bannerContainer = document.getElementsByClassName("banner-container");
        let aboutContainer = document.getElementById("about");
        let servicesContainer = document.getElementById("services");
        let portfolioContainer = document.getElementById("portfolio");
        

        if(bannerContainer && bannerContainer.length > 0){
            bannerHeight = bannerContainer[0].clientHeight || 0;
        }

        if(aboutContainer){
            if(aboutContainer.classList.contains("active")){
                aboutContainer.classList.remove("active");
            }

            aboutHeight = (aboutContainer.clientHeight || 0) + bannerHeight;
        }

        if(servicesContainer){
            if(servicesContainer.classList.contains("active")){
                servicesContainer.classList.remove("active");
            }

            servicesHeight = (servicesContainer.clientHeight || 0) + aboutHeight;
        }

        if(portfolioContainer){
            if(portfolioContainer.classList.contains("active")){
                portfolioContainer.classList.remove("active");
            }

            portfolioHeight = (portfolioContainer.clientHeight || 0) + servicesHeight;
        }


        return {
            bannerHeight,
            aboutHeight,
            servicesHeight,
            portfolioHeight
        };
    }

    getActiveClassId = () => {
        let {bannerHeight, aboutHeight, servicesHeight, portfolioHeight} = this.getSectionsHeight();
        const pageHeight = window.pageYOffset; //current height
        let targetId = "";

        if (pageHeight >= 0 && pageHeight <= bannerHeight) {
            //do nothing
        } else if(pageHeight > bannerHeight && pageHeight <= aboutHeight){
            targetId = "nav_about";
        } else if (pageHeight > aboutHeight && pageHeight <= servicesHeight){
            targetId = "nav_services";
        } else if (pageHeight > servicesHeight && pageHeight <= portfolioHeight){
            targetId = "nav_portfolio";
        } else {
            targetId = "nav_contact";
        }

        return targetId;
    }

    render(){
        return (
            <div>
                <div className="banner-container">
                    <div className="overlay-banner-container">
                        <Header />
                        <HeaderInfo isHomeScreen />
                    </div>
                </div>

                <About />
                <OurServices />
                <Portfolio />
                <OutCustomers />
                <OurTechnologies />
                <Contact />
            </div>
        );
    }
}