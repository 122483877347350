import React, { Component } from 'react';
import Header from '../components/Header';
import HeaderInfo from '../components/HeaderInfo';
import { JOB_IDS, JOB_TITLES } from '../config/constants';
import ReactLoading from 'react-loading';
import "../assets/styles/contact.css"
import "../assets/styles/careers.css"

export default class ApplyJob extends Component {

    state = {
        title: "",
        id: "",
        errorMessage: "",
        successMessage: "",
        isLoading: false,
        name: "",
        phone: "",
        email: "",
        personalNotes: "",
        links: [],
        gitHub: "",
        linkedIn: "",
        portfolio: ""
    }

    componentDidMount() {
        try {
            let id = this.props.match.params.id;
            this.updateTitleById(id);
            this.setState({ id })
        } catch (e) { console.error(e) }
    }

    updateTitleById = (id) => {

        let title = "";

        switch (id) {
            case JOB_IDS.BACKEND:
                title = JOB_TITLES.BACKEND;
                break;

            case JOB_IDS.MOBILE:
                title = JOB_TITLES.MOBILE;
                break;

            case JOB_IDS.FULLSTACK:
            default:
                title = JOB_TITLES.FULLSTACK;
                break;
        }

        this.setState({ title })
    }

    render() {

        return (
            <div>
                <div className="banner-container">
                    <div className="overlay-banner-container">
                        <Header applyJob />
                        <HeaderInfo applyJob title={this.state.title} />
                    </div>
                </div>

                <div className="section-container bg-light">
                    <h2 className="section-title">{this.state.title} - {this.state.id}</h2>
                    <h4 className="section-sub-title"> Remote Job</h4>
                </div>
                <div className="contact-form-container">
                    <div className="contact-form">
                        <div className="form-area">
                            <div className='apply-job-container contact'>
                                <form className="form animated fadeInUp">

                                    <label>
                                        <input type="text" name="name" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="subject text-right" />
                                        <div className="label subject-text">Full Name</div>
                                    </label>

                                    <label>
                                        <input type="email" name="email" className="email text-right" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                        <div className="label email-text">Email</div>
                                    </label>

                                    <label>
                                        <input type="text" name="phone" className="subject text-right" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                        <div className="label subject-text">Phone</div>
                                    </label>

                                    <label>
                                        <textarea rows="8" cols="80" className="help-box text-right" value={this.state.personalNotes} onChange={(e) => this.setState({ personalNotes: e.target.value })}></textarea>
                                        <div className="label help-text">Personal Notes</div>
                                    </label>

                                    <label>
                                        <input type="text" name="github" className="subject text-right" value={this.state.gitHub} onChange={(e) => this.setState({ gitHub: e.target.value })} />
                                        <div className="label subject-text">GitHub Link (Optional)</div>
                                    </label>
                                    <label>
                                        <input type="text" name="linked" className="subject text-right" value={this.state.linkedIn} onChange={(e) => this.setState({ linkedIn: e.target.value })} />
                                        <div className="label subject-text">LinkedIn Link (Optional)</div>
                                    </label>
                                    <label>
                                        <input type="text" name="portfolio" className="subject text-right" value={this.state.portfolio} onChange={(e) => this.setState({ portfolio: e.target.value })} />
                                        <div className="label subject-text">Persoanl Website/Portfolio (Optional)</div>
                                    </label>

                                    <label>
                                        <input type="text" value="" onChange={() => {}} className="subject text-right" onClick={() => document.getElementById("cv").click()} />
                                        <div className="label subject-text">Attach CV</div>
                                        <input type="file" id='cv' style={{opacity: 0, visibility: "collapse"}} name="cv" className="subject text-right" />
                                    </label>

                                    <div className="text-right"><label className="text-success">{this.state.successMessage}</label></div>
                                    <div className="text-right"><label className="text-danger">{this.state.errorMessage}</label></div>

                                    {this.state.isLoading ?
                                        <div className="text-center" style={{ margin: "0 auto" }}>
                                            <ReactLoading type="spin" color="rgb(41, 171, 226)" height={50} width={50} className="text-center m-auto" />
                                        </div>
                                        :
                                        <div className="submit-area">
                                            <input type="button" id="submit" className="submit-button" value="Apply Now!" onClick={this.submitForm} />
                                            <i className="far fa-paper-plane"></i>
                                        </div>
                                    }

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}