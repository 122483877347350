import React, { Component } from 'react';
import { SCREEN_NAMES } from '../config/constants';

export default class Header extends Component{

    state = {
        isMenuClosed: true,
        menuShowClass: 'collapse',
        companyName: 'Infinite Sol',
        companyArr: [],
        isStickyHeader: false,
        menu: [
            {id: "about", title: "מי אנחנו"},
            {id: "services", title: "השירותים שלנו"},
            {id: "portfolio", title: "תיק העבודות"},
            {id: "contact", title: "צור קשר"},
            {id: "careers", title: "משרות", isRedirect: true, redirectUrl: SCREEN_NAMES.CAREERS_SCREEN},
        ]
    }

    componentDidMount(){
        window.addEventListener('scroll', this.windowScrollerd);

        const companyArr = [];
        let isPrimary = true;

        for(let i = 0; i < this.state.companyName.length; i++){

            companyArr.push({
                name: this.state.companyName.charAt(i),
                isPrimary
            });

            if(this.state.companyName.charAt(i) === ' '){
                continue;
            }

            isPrimary = !isPrimary;
        }

        this.setState({companyArr});
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.windowScrollerd);
    }

    toggleMenu = () => {
        this.setState({isMenuClosed: !this.state.isMenuClosed});

        this.setState({menuShowClass: 'collapsing'});
        
        setTimeout(() => {
            this.setState({menuShowClass: this.state.isMenuClosed ? 'collapse': 'collapse show'})
        }, 200);
    }

    windowScrollerd = () => {

        const header = document.getElementById("header");
        const sticky = header.offsetTop;

        if(window.pageYOffset > sticky){
            header.classList.add('sticky');
            this.setState({ isStickyHeader: true });
        } else {
            header.classList.remove('sticky');
            this.setState({ isStickyHeader: false })
        }
    }

    updateActiveClass = (id) => {
        let {menu} = this.state;

        for(let i = 0; i < menu.length; i++){
            let curr = document.getElementById("nav_" + menu[i].id);

            if(curr && curr.classList.contains("active")){
                curr.classList.remove("active")
            }
        }

        let currItem = document.getElementById("nav_" + id);
        currItem.classList.add("active");
    }

    openScreen = (url) => {
        window.location.href = url;
    }

    itemClicked = (val) => {
        if (val.isRedirect) {
            this.openScreen(val.redirectUrl);
        }

        this.updateActiveClass(val.id)
    }

    render(){

        // const stickyHeaderLogo = require('../assets/images/logo-png.png');
        // const stickyHeaderLogo = require('../assets/images/newLogo/logo.png');
        const headerLogo = require('../assets/images/logo-png-white.png');
        // const stickyHeaderLogo = require('../assets/images/new_logo.png');
        // const relativeHeaderLogo = require('../assets/images/new_logo.png');

        return (
            <div className="header-container header" id="header">
                <nav className="navbar navbar-expand-lg transparent-bg">
                    {/* eslint-disable-next-line */}
                    <a className="navbar-brand" href="#">
                        <div className="d-flex justify-content-center align-items-center ml-5">
                            <img alt="" src={headerLogo} style={{width: 120}} />
                        </div>
                    </a>
                    <button id="buttonToggleMenu" className={"mr-5 navbar-toggler " + (this.state.isMenuClosed ? 'collapsed' : '')} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={this.state.isMenuClosed ? "false" : "true"} aria-label="Toggle navigation"
                        onClick={() => this.toggleMenu()}>
                        <span className="navbar-toggler-icon"></span>
                        <i className="fa fa-bars" style={{color: "white"}}></i>
                    </button>

                    <div className={"mr-5 navbar-collapse " + (this.state.menuShowClass)} id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto navbar-menu-container">
                            {
                                this.state.menu.map( (val, ind) => 
                                    <li className="nav-item" key={ind}>
                                        <a onClick={() => this.itemClicked(val)} className={`nav-link ${val.id === "careers" && this.props.careers ? "active": ""}`} id={"nav_" + val.id} href={"/#" + val.id}>{val.title}</a>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}