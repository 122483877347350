export const Constant = {
    API_URL: "http://api.infinitesol.co.il"
}

export const SCREEN_NAMES = {
    HOME_SCREEN: '/',
    CAREERS_SCREEN: '/careers',
    APPLY_JOB_SCREEN: '/apply'
}

export const JOB_IDS = {
    FULLSTACK: "6548001",
    BACKEND: "6548002",
    MOBILE: "6548003",
    QA: "6548004",
}

export const JOB_TITLES = {
    FULLSTACK: "Fullstack JavaScript Developer",
    BACKEND: "Node.js Server Developer",
    MOBILE: "React Native Developer",
    QA: "QA Automation Enginner - Student Role"
}