import React, { Component } from 'react';
import Header from '../components/Header';
import HeaderInfo from '../components/HeaderInfo';
import About from '../components/About';
import OpenCareers from '../components/OpenCareers';

export default class Home extends Component{

    render(){
        return (
            <div>
                <div className="banner-container">
                    <div className="overlay-banner-container">
                        <Header careers />
                        <HeaderInfo careers />
                    </div>
                </div>

                <About />
                <OpenCareers />

            </div>
        );
    }

}