import React from 'react';
import {Route, Switch} from 'react-router-dom';
import { SCREEN_NAMES } from '../config/constants';
import Home from '../screens/Home';
import Careers from '../screens/Careers';
import NotFound from '../screens/NotFound';
import ApplyJob from '../screens/ApplyJob';

const AppRouter = () => {
    return (
        <Switch>
            <Route exact path={SCREEN_NAMES.HOME_SCREEN} component={Home} />
            <Route exact path={SCREEN_NAMES.CAREERS_SCREEN} component={Careers} />
            <Route exact path={`${SCREEN_NAMES.APPLY_JOB_SCREEN}/:id`} component={ApplyJob} />

            <Route component={NotFound} />
        </Switch>
    );
}

export default AppRouter;