import React, { Component } from 'react';
import Carsousel from "react-elastic-carousel";
import "../assets/styles/portfolio.css";
import PortfolioModal from './PortfolioModal';

const breakPoints = [
    {width: 1, itemsToShow: 1},
    {width: 550, itemsToShow: 2},
    {width: 768, itemsToShow: 3},
    {width: 1200, itemsToShow: 4}
];

const portfolioTypes = {
    MOBILE: "mobile",
    WEB: "web"
};

export default class Portfolio extends Component{
    
    state = {
        portfolios: [],
        selectedItem: null,
        modalShow: false,
        currentDisplayItemsNumber: 1
    }
    
    componentDidMount(){

        this.updateCurrentDisplayItemsNumber();
        let {portfolios} = this.state;
        
        portfolios.push({
            id: 1,
            name: "Mohdent Medical",
            description: "חנות אינטרנט אשר חושפת עולם רחב של מוצרי הרפואה והשיניים, ממשק קל וידודתי למשתמש, הצגה של המוצרים ויצירת קשר בלחיצה אחת עם העסק",
            type: portfolioTypes.WEB,
            image: require('../assets/images/portfolio/mohdent.png'),
            webURL: "https://mohdent-medical.com/",
            appleURL: "#",
            androidURL: "#"
        })

        portfolios.push({
            id: 2,
            name: "Delivoo",
            description: "אפליקציית מובייל המאפשרת ללקוחות הזמנת אוכל, ממתקים ועוד המון פריטים, מעקב אחרי ההזמנה בזמן אמת, הצגת השליחים על המפה בזמן אמת, קבלת התראות על כל עדכון בהזמנה ועוד פעולות",
            type: portfolioTypes.MOBILE,
            image: require('../assets/images/portfolio/delivoo.png'),
            webURL: "#",
            appleURL: "https://apps.apple.com/us/app/delivoo/id1495205013",
            androidURL: "https://play.google.com/store/apps/details?id=com.infinitesol.delivoo_app_2"
        })

        portfolios.push({
            id: 3,
            name: "דיליבו שליח",
            description: "אפליקציית מובייל המאפשרת ללקוחות Delivoo להזמין אוכל, ממתק, או כל דבר מהחנויות של האפליקציה ולקבל אותם על ידי שליח, שליח זה יכול לנסוע לפי מפה גוגל המוגדרת באפליקציה מהמיקום הנוכחי שלו לבית העסק או לכתובת הלקוח הרצויה",
            type: portfolioTypes.MOBILE,
            image: require('../assets/images/portfolio/delivooDeliver.png'),
            webURL: "#",
            appleURL: "https://apps.apple.com/us/app/%D7%93%D7%99%D7%9C%D7%99%D7%91%D7%95-%D7%A9%D7%9C%D7%99%D7%97/id1501583951",
            androidURL: "https://play.google.com/store/apps/details?id=com.infinitesol.deliveryboy_2"
        })

        portfolios.push({
            id: 4,
            name: "דיליבו עסקים",
            description: "אפליקציית מובייל המיועדת לעסקים כגון: מסעדות, אטליז, חנות קינוחים, סופרמרקט ועוד. אפליקציה זו מאפשרת לבעל העסק לקבל הזמנות, לנהל אותם ולהגדיל את ההכנסה היומית של העסק",
            type: portfolioTypes.MOBILE,
            image: require('../assets/images/portfolio/delivooDeliver.png'),
            webURL: "#",
            appleURL: "#",
            androidURL: "https://play.google.com/store/apps/details?id=com.infinitesol.businessmanagemarketplaceapp"
        })

        portfolios.push({
            id: 5,
            name: "Alotan",
            description: "אתר זימון תורים לספר מקצועי בלי לחכות בתור לזמן ארוך, 3 כפתורים והתור שלך כבר נקבע",
            type: portfolioTypes.WEB,
            image: require('../assets/images/portfolio/alotan.png'),
            webURL: "#",
            appleURL: "#",
            androidURL: "#"
        })

        portfolios.push({
            id: 6,
            name: "Woodrox",
            description: "חנות אינטרנט למכירת מוצרי בית ומשרד, אתר ידודתי למשתמש בהתאמה אישית לכל מכשיר",
            type: portfolioTypes.WEB,
            image: require('../assets/images/portfolio/woodrox.png'),
            webURL: "#",
            appleURL: "#",
            androidURL: "#"
        })

        portfolios.push({
            id: 7,
            name: "3DMail",
            description: "אפליקציית מובייל המאפשרת לכל משתמש לשלוח כל פריט שברצונו מכל נקודה לכל נקודה בישראל, עם אפשרות מעקב אחרי סטטוס ההזמנה וקבלת התראה ברגע סיום המשלוח",
            type: portfolioTypes.MOBILE,
            image: require('../assets/images/portfolio/uberNextGen.png'),
            webURL: "#",
            appleURL: "#",
            androidURL: "#"
        })

        portfolios.push({
            id: 8,
            name: "3DMail Driver",
            description: "אפליקציית שליחת מוצרים מבית הלקוח עד יעד האיסוף שלהם, ממשק ידודתי למשתמש, מעקב אחרי כל ההזמנות שהשליח ביצע ועוד",
            type: portfolioTypes.MOBILE,
            image: require('../assets/images/portfolio/3dmailDriver.jpg'),
            webURL: "#",
            appleURL: "#",
            androidURL: "#"
        })

        portfolios.push({
            id: 9,
            name: "עוזר בטיחות",
            description: "עריכת רשימת תיוג באתר ישירות מהסמארטפון. מותאם לתקנה החדשה. דיווח תקלות אוטומטי לגורמים הרלוונטיים באתר",
            type: portfolioTypes.MOBILE,
            image: require('../assets/images/portfolio/asstSaftey.png'),
            webURL: "#",
            appleURL: "https://apps.apple.com/il/app/%D7%A2%D7%95%D7%96%D7%A8-%D7%91%D7%98%D7%99%D7%97%D7%95%D7%AA/id1535855929?l=iw",
            androidURL: "https://play.google.com/store/apps/details?id=com.blirokratia.assest.saftey"
        })

        portfolios.push({
            id: 10,
            name: "ממונה בטיחות",
            description: "עריכת מבדקי בטיחות ישירות מהסמארטפון. צירוף תמונות, אישורים ותקנות רלוונטיות. שליטה קלה במספר אתרים שונים למשתמש יחיד",
            type: portfolioTypes.MOBILE,
            image: require('../assets/images/portfolio/extSaftey.png'),
            webURL: "https://play.google.com/store/apps/details?id=com.blirokratia",
            appleURL: "https://apps.apple.com/il/app/%D7%9E%D7%9E%D7%95%D7%A0%D7%94-%D7%91%D7%98%D7%99%D7%97%D7%95%D7%AA/id1535855263?l=iw",
            androidURL: "#"
        })

        portfolios.push({
            id: 11,
            name: "חתימה דיגטלית",
            description: "שליחת מסמכים לחתימה דיגיטלית ומעקב אונליין בעזרת ממשק ידידותי ללא צורך בסריקה, שליחת פקס או הדפסת המסמך",
            type: portfolioTypes.WEB,
            // image: require('../assets/images/logo-jpeg.jpg'),
            image: require('../assets/images/newLogo/logo.png'),
            webURL: "#",
            appleURL: "#",
            androidURL: "#"
        })

        portfolios.push({
            id: 12,
            name: "888 carlist",
            description: "אפליקציית קארליסט הינה אפליקציית חיפוש וקניה של רכבים מבעלי מגרשים הכי נפוצים בישראל במחיר הכי זול שתוכל למצוא",
            type: portfolioTypes.MOBILE,
            image: require('../assets/images/portfolio/carlist.png'),
            webURL: "#",
            appleURL: "https://apps.apple.com/us/app/888-carlist/id1561642334",
            androidURL: "https://play.google.com/store/apps/details?id=com.msdevelopers.carlistcustomer&gl=IL"
        })

        portfolios.push({
            id: 13,
            name: "קארליסט מגרשים",
            description: "אפליקציית קארליסט מגרשים הינה אפלקציה המאפשרת לבעלי המגרשים לנהל את העסק שלהם בדיגטל באופן קל ומאובטח",
            type: portfolioTypes.MOBILE,
            image: require('../assets/images/portfolio/carlist_owner.png'),
            webURL: "#",
            appleURL: "https://apps.apple.com/us/app/%D7%A7%D7%90%D7%A8%D7%9C%D7%99%D7%A1%D7%98-%D7%9E%D7%92%D7%A8%D7%A9%D7%99%D7%9D/id1561804570",
            androidURL: "https://play.google.com/store/apps/details?id=com.msdevelopers.carlistowner&gl=IL"
        })
        

        this.setState({ portfolios });
        window.addEventListener('resize', this.updateCurrentDisplayItemsNumber);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateCurrentDisplayItemsNumber);
    }

    updateCurrentDisplayItemsNumber = () => {
        let currWidth = window.innerWidth;
        let items = 1;

        if (currWidth >= 1200) {
            items = 4;
        } else if (currWidth >= 768) {
            items = 3;
        } else if (currWidth >= 550) {
            items = 2;
        }

        this.setState({ currentDisplayItemsNumber: items });
    }

    itemClicked = (index) => {

        let {portfolios} = this.state;

        if (index < portfolios.length){
            this.setState({ selectedItem: portfolios[index] });
            
            setTimeout(() => {
                this.setState({ modalShow: true });
            }, 350);
        }
    }

    handleLastItemScrollEnd = (index) => {
        let { currentDisplayItemsNumber } = this.state;
        let lastItemIndex = Math.floor(this.state.portfolios.length / currentDisplayItemsNumber);

        if (index === lastItemIndex) {
            this.carouselRef.goTo(0);
        }
    }

    render(){
        return (
            <div id="portfolio" className="section-container bg-white">
                <PortfolioModal show={this.state.modalShow} 
                    onHide={() => this.setState({modalShow: false})}
                    selectedItem={this.state.selectedItem}
                    portfolioTypes={portfolioTypes}/>

                <h2 className="section-title">תיק העבודות שלנו</h2>
                <h4 className="section-sub-title">
                הנה טעימה קטנה מהעבודה שלנו
                </h4>

                <div className="portfolio-area">
                    <Carsousel breakPoints={breakPoints}
                        ref={(ref) => this.carouselRef = ref}
                        enableAutoPlay
                        enableSwipe
                        pagination={false}
                        showArrows={true}
                        onNextEnd={(_, index) => this.handleLastItemScrollEnd(index)}
                        autoPlaySpeed={3000}>
                        {
                            this.state.portfolios.map((val, ind) => 
                                <div className="box" key={ind} onClick={() => this.itemClicked(ind)}>
                                    <img alt="" src={val.image} />
                                    <div className="content">
                                        <h3>{val.name}</h3>
                                        <h4>{val.type === portfolioTypes.WEB ? "אתר אינטרנט" : "אפליקציית מובייל"}</h4>
                                        <p>{val.description}</p>
                                    </div>    
                                </div>
                            )
                        }
                    </Carsousel>
                </div>
            </div>
        );
    }
}