import React, { Component } from 'react';

export default class About extends Component{
    render(){
        return (
            <div id="about" className="section-container bg-white">
                <h2 className="section-title">מי אנחנו</h2>
                <h4 className="section-sub-title">
                אנחנו לא מסתתרים, אנחנו עומדים גבוה מול האתגר
                </h4>
                <div className="text-center">
                    <label className="section-description dir-rtl">
                    חברת <span className="color-primary">אינפנט סול</span> הקומה בשנת 2019 על ידי מספר אנשי תוכנה מהמובילים בתחום על מנת לקדם ולהוביל עסקים קדימה.
                    חברתנו מתמחה בכל מיני תחומים כגון:
                    בניית דפי נחיתה, מערכות זימון תורים, מערכת ניהול CRM, אפלקציות מובייל, אפלקציות ואתרים לעסקים קטנים, בינוניים ואפילו גדולים.
                    <br />
                    ניהול דפי פייסבוק, אינסטגרם ושיווק דיגטלי מתקדם
                    </label>
                </div>
            </div>
        );
    }
}