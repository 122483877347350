import React, { useEffect, useState } from 'react';
import Carsousel from "react-elastic-carousel";

const breakPoints = [
    {width: 1, itemsToShow: 1},
    {width: 550, itemsToShow: 2},
    {width: 768, itemsToShow: 3},
    {width: 1200, itemsToShow: 4}
];

const OurTechnologies = () => {

    const [technologies, setTechnologies] = useState([]);

    useEffect(() => {

        let arr = [];

        arr.push({img: require('../assets/images/tech/java.jpg')});
        arr.push({img: require('../assets/images/tech/net.png')});
        arr.push({img: require('../assets/images/tech/mysql.png')});
        arr.push({img: require('../assets/images/tech/sqlserver.png')});
        arr.push({img: require('../assets/images/tech/google_maps.jpg')});
        arr.push({img: require('../assets/images/tech/node.png')});
        arr.push({img: require('../assets/images/tech/react.png')});
        arr.push({img: require('../assets/images/tech/react_native.png')});
        arr.push({img: require('../assets/images/tech/mongo.jpg')});
        arr.push({img: require('../assets/images/tech/gcp.png')});
        arr.push({img: require('../assets/images/tech/firebase.png')});
        arr.push({img: require('../assets/images/tech/aws.png')});

        setTechnologies(arr);
    }, []);

    return (
        <div className="section-container bg-white">
            <h2 className="section-title">הטכנולוגיות שלנו</h2>
            <h4 className="section-sub-title">אנחנו עובדים עם הטכנולוגיות הכי חדשניות בשוק</h4>

            <div className="portfolio-area" style={{marginTop: 80}}>
                <Carsousel breakPoints={breakPoints}
                    enableAutoPlay
                    enableSwipe
                    pagination={false}
                    showArrows={true}
                    autoPlaySpeed={3000}>
                    {
                        technologies.map((val, ind) =>
                            <img key={ind} alt="" src={val.img} style={{objectFit: "contain", height: 100, width: "30%", aspectRatio: "3/2"}} />
                        )
                    }
                </Carsousel>
            </div>
        </div>
    )
}

export default OurTechnologies;