import React, { Component } from 'react';

import "../assets/styles/services.css";
import "../assets/styles/careers.css";
import JobDescription from './JobDescription';
import { JOB_IDS, JOB_TITLES } from '../config/constants';

export default class OpenCareers extends Component {

    state = {
        careers: []
    }

    componentDidMount(){
        let careers = [];

        //Full-Stack
        careers.push({
            id: JOB_IDS.FULLSTACK,
            jobDescription: JOB_TITLES.FULLSTACK,
            //Job Description
            description: "We are seeking a talented and experienced Fullstack JavaScript Developer to join our team. As a Fullstack JavaScript Developer, you will be responsible for developing and maintaining web applications using the latest JavaScript frameworks and technologies. You will work closely with our development team, collaborating on various projects and contributing to the entire software development lifecycle. The ideal candidate should have a solid understanding of both frontend and backend development, along with a passion for building high-quality, scalable, and performant applications.",
            //Closure Description
            teamDesc: "Join our team and be part of an innovative and collaborative environment where you can contribute to exciting projects and make a significant impact. We offer competitive compensation, opportunities for professional growth, and a supportive work culture.",
            resp: [ //Responsibilities
                "Develop and maintain web applications using JavaScript, HTML, and CSS.",
                "Design and implement frontend user interfaces using modern JavaScript frameworks such as React, Angular, or Vue.js.",
                "Develop server-side logic and APIs using Node.js or other backend frameworks.",
                "Collaborate with the design team to create responsive and visually appealing interfaces.",
                "Write clean, modular, and well-documented code that follows best practices and coding standards.",
                "Conduct thorough testing and debugging to ensure the quality and performance of the applications.",
                "Participate in code reviews to provide and receive constructive feedback.",
                "Collaborate with cross-functional teams to gather requirements and implement new features.",
                "Stay up-to-date with the latest industry trends, technologies, and best practices in JavaScript development.",
                "Assist in troubleshooting and resolving technical issues in production environments."
            ],
            qual: [ //Qualifications
                "Bachelor's degree in Computer Science, Software Engineering, or a related field (or equivalent experience).",
                "Proven experience as a Fullstack JavaScript Developer or similar role.",
                "Strong proficiency in JavaScript, HTML, CSS, and related web technologies.",
                "Experience with frontend frameworks such as React, Angular, or Vue.js.",
                "Experience with backend development using Node.js or other backend frameworks.",
                "Familiarity with database systems like MongoDB, MySQL, or PostgreSQL.",
                "Knowledge of version control systems (e.g., Git) and agile development methodologies.",
                "Strong problem-solving skills and attention to detail.",
                "Excellent communication and collaboration skills.",
                "Ability to work effectively in a fast-paced and dynamic environment.",
                "A passion for learning and staying updated with emerging technologies."
            ],
            skills: [ //Preferred Skills
                "Experience with serverless architecture and cloud platforms (e.g., AWS, Azure, or Google Cloud Platform).",
                "Knowledge of GraphQL and RESTful APIs.",
                "Experience with testing frameworks (e.g., Jest, Mocha, Chai) and test-driven development.",
                "Understanding of containerization and orchestration tools (e.g., Docker, Kubernetes).",
                "Familiarity with CI/CD pipelines and deployment automation.",
            ],
        })

        //Back-End
        careers.push({
            id: JOB_IDS.BACKEND,
            jobDescription: JOB_TITLES.BACKEND,
            //Job Description
            description: "We are seeking a skilled and motivated Backend Developer to join our development team. As a Backend Developer, you will be responsible for designing, developing, and maintaining the server-side logic and databases that power our web applications. You will collaborate closely with frontend developers, product managers, and other stakeholders to deliver high-quality and scalable software solutions. The ideal candidate should have a strong understanding of backend technologies, excellent problem-solving skills, and a passion for building robust and efficient systems.",
            //Closure Description
            teamDesc: "Join our dynamic team and work on exciting projects where you can make a significant impact. We offer competitive compensation, professional development opportunities, and a supportive work culture that encourages innovation and collaboration.",
            resp: [ //Responsibilities
                "Design, develop, and maintain server-side applications and APIs using backend technologies such as Node.js, Python, Ruby, or Java.",
                "Collaborate with frontend developers to integrate the frontend and backend components of web applications.",
                "Develop and optimize database structures and queries for efficient data storage and retrieval.",
                "Write clean, modular, and well-documented code following best practices and coding standards.",
                "Implement security measures and data protection mechanisms to ensure the integrity and confidentiality of user data.",
                "Conduct thorough testing and debugging to identify and resolve issues or performance bottlenecks.",
                "Collaborate with cross-functional teams to gather requirements and define technical specifications.",
                "Work closely with DevOps engineers to deploy and maintain applications in production environments.",
                "Stay up-to-date with the latest trends, technologies, and best practices in backend development.",
                "Continuously improve development processes and contribute to the overall technical excellence of the team."
            ],
            qual: [ //Qualifications
                "Bachelor's degree in Computer Science, Software Engineering, or a related field (or equivalent experience).",
                "Proven experience as a Backend Developer or similar role.",
                "Strong proficiency in one or more backend programming languages, such as Node.js, Python, Ruby, or Java.",
                "Experience with backend frameworks (e.g., Express.js, Django, Ruby on Rails) and RESTful API development.",
                "Solid understanding of database systems (e.g., MySQL, PostgreSQL, MongoDB) and data modeling principles.",
                "Familiarity with version control systems (e.g., Git) and agile development methodologies.",
                "Strong problem-solving skills and attention to detail.",
                "Excellent communication and collaboration skills.",
                "Ability to work effectively in a team environment and independently when required.",
                "Passion for learning and staying updated with emerging backend technologies and trends."
            ],
            skills: [ //Preferred Skills
                "Experience with cloud platforms such as AWS, Azure, or Google Cloud Platform.",
                "Knowledge of containerization technologies (e.g., Docker) and orchestration tools (e.g., Kubernetes).",
                "Understanding of caching mechanisms (e.g., Redis, Memcached) and message queue systems (e.g., RabbitMQ, Kafka).",
                "Familiarity with testing frameworks and methodologies (e.g., unit testing, integration testing).",
                "Experience with performance optimization and scalability techniques.",
                "Strong understanding of security principles and best practices in web application development."
            ],
        })

        //Mobile Developer
        careers.push({
            id: JOB_IDS.MOBILE,
            jobDescription: JOB_TITLES.MOBILE,
            //Job Description
            description: "We are looking for a skilled and passionate Mobile Apps Developer to join our development team. As a Mobile Apps Developer, you will be responsible for designing, developing, and maintaining mobile applications for iOS and Android platforms. You will work closely with our cross-functional team, including designers and backend developers, to deliver user-friendly and high-performance mobile solutions. The ideal candidate should have a strong understanding of mobile app development frameworks, excellent problem-solving skills, and a drive for creating exceptional user experiences.",
            //Closure Description
            teamDesc: "Join our innovative team and contribute to the development of cutting-edge mobile applications. We offer a competitive salary, professional development opportunities, and a collaborative work environment that encourages creativity and growth.",
            resp: [ //Responsibilities
                "Develop, test, and maintain mobile applications for iOS and Android platforms using programming languages such as Swift, Kotlin, or React Native.",
                "Collaborate with designers and frontend developers to implement engaging and intuitive user interfaces.",
                "Integrate mobile applications with backend services and APIs.",
                "Optimize mobile applications for performance, responsiveness, and efficiency.",
                "Ensure the security and privacy of user data by implementing appropriate measures and best practices.",
                "Conduct thorough testing and debugging to identify and resolve issues or performance bottlenecks.",
                "Stay up-to-date with the latest trends, technologies, and best practices in mobile app development.",
                "Collaborate with cross-functional teams to gather requirements and define technical specifications.",
                "Continuously improve development processes and contribute to the overall technical excellence of the team.",
                "Provide technical guidance and support to junior developers when required."
            ],
            qual: [ //Qualifications
                "Bachelor's degree in Computer Science, Software Engineering, or a related field (or equivalent experience).",
                "Proven experience as a Mobile Apps Developer or similar role.",
                "Strong proficiency in mobile app development frameworks such as Swift, Kotlin, or React Native.",
                "Solid understanding of mobile app development principles, including UI/UX design, performance optimization, and security.",
                "Experience with mobile app testing, debugging, and performance profiling.",
                "Familiarity with RESTful APIs and integrating mobile apps with backend services.",
                "Knowledge of version control systems (e.g., Git) and agile development methodologies.",
                "Strong problem-solving skills and attention to detail.",
                "Excellent communication and collaboration skills.",
                "Ability to work effectively in a team environment and independently when required.",
                "Passion for learning and staying updated with emerging mobile app development technologies and trends."
            ],
            skills: [ //Preferred Skills
                "Experience publishing mobile apps on the App Store and/or Google Play Store.",
                "Knowledge of backend development frameworks such as Node.js, Django, or Ruby on Rails.",
                "Familiarity with cloud platforms such as AWS, Azure, or Google Cloud Platform.",
                "Understanding of hybrid app development using frameworks like Flutter or Xamarin.",
                "Experience with mobile app analytics and crash reporting tools.",
                "Understanding of mobile app security and encryption techniques.",
                "Strong understanding of user interface and user experience design principles."
            ],
        })

        //QA
        careers.push({
            id: JOB_IDS.QA,
            jobDescription: JOB_TITLES.QA,
            //Job Description
            description: "We are seeking a detail-oriented and experienced QA Engineer to join our team. As a QA Engineer, you will play a crucial role in ensuring the quality and reliability of our software products. You will be responsible for designing and executing test plans, identifying and reporting bugs, and working closely with the development team to resolve issues. The ideal candidate should have a strong understanding of software testing methodologies, excellent problem-solving skills, and a passion for delivering high-quality software solutions.",
            //Closure Description
            teamDesc: "Join our dedicated team and contribute to the quality and success of our software products. We offer a competitive salary, professional development opportunities, and a collaborative work environment that fosters innovation and growth.",
            resp: [ //Responsibilities
                "Develop and execute comprehensive test plans and test cases for software applications.",
                "Conduct functional, regression, and performance testing to identify software defects and ensure proper functionality.",
                "Collaborate with the development team to understand requirements and provide input on design and usability aspects from a testing perspective.",
                "Participate in the review of software requirements and specifications to provide feedback on testability and potential issues.",
                "Create and maintain test documentation, including test plans, test cases, and test reports.",
                "Perform manual testing as well as automated testing using appropriate testing tools and frameworks.",
                "Identify, document, and track bugs and issues, following up with the development team to ensure timely resolution.",
                "Investigate and troubleshoot reported issues, working with cross-functional teams to replicate and resolve problems.",
                "Continuously improve the QA process by proposing and implementing enhancements, including test automation and efficiency improvements.",
                "Stay up-to-date with industry best practices, emerging technologies, and trends in software quality assurance."
            ],
            qual: [ //Qualifications
                "Bachelor's degree in Computer Science, Software Engineering, or a related field (or equivalent experience).",
                "Proven experience as a QA Engineer or Software Tester.",
                "Strong understanding of software testing methodologies, tools, and processes.",
                "Experience with both manual and automated testing techniques.",
                "Proficiency in using testing frameworks and tools, such as Selenium, JUnit, TestNG, or similar.",
                "Familiarity with bug tracking systems (e.g., JIRA, Bugzilla) and version control systems (e.g., Git).",
                "Knowledge of different types of testing, including functional, regression, performance, and usability testing.",
                "Strong analytical and problem-solving skills, with attention to detail.",
                "Excellent communication and collaboration skills.",
                "Ability to work effectively in a team environment and independently when required.",
                "Familiarity with agile development methodologies.",
                "Understanding of software development lifecycle and quality assurance processes.",
                "Knowledge of programming languages, such as Java, Python, or JavaScript, is a plus.",
                "ISTQB or other relevant certifications are a plus."
            ],
            skills: [ //Preferred Skills
                "Test Automation: Experience with test automation frameworks and tools, such as Selenium WebDriver, Cypress, Appium, or similar. Knowledge of scripting languages like Python, Java, or JavaScript for creating automated test scripts.",
                "Performance Testing: Familiarity with performance testing tools like JMeter, LoadRunner, or Gatling. Ability to design, execute, and analyze performance tests to ensure the scalability and reliability of software applications.",
                "Security Testing: Understanding of security testing principles and knowledge of tools such as OWASP ZAP or Burp Suite for identifying vulnerabilities and conducting security assessments.",
                "Continuous Integration/Continuous Deployment (CI/CD): Experience with CI/CD pipelines and familiarity with tools like Jenkins, GitLab CI/CD, or CircleCI. Understanding of integrating testing activities into the CI/CD process for efficient and automated testing.",
                "Test Management Tools: Proficiency in using test management tools such as TestRail, Zephyr, or Xray for test case management, execution tracking, and reporting.",
                "API Testing: Knowledge of API testing tools like Postman, SoapUI, or REST Assured. Ability to validate API functionality, perform integration testing, and ensure proper handling of data formats (JSON, XML) and authentication mechanisms (OAuth, JWT).",
                "Database Testing: Experience in testing databases, including validating data integrity, performing data migration testing, and executing SQL queries for data validation.",
                "Mobile Testing: Familiarity with mobile testing frameworks and tools like Appium, XCUITest, or Espresso. Knowledge of testing mobile applications on various devices, OS versions, and screen resolutions.",
                "Agile Methodologies: Understanding of Agile/Scrum development methodologies and the ability to work in an Agile environment, including participating in sprint planning, stand-ups, and retrospectives.",
                "Analytical Skills: Strong problem-solving and analytical skills to identify, troubleshoot, and isolate software defects effectively. Ability to analyze complex systems and workflows to design comprehensive test strategies.",
                "Communication Skills: Excellent written and verbal communication skills to effectively collaborate with cross-functional teams, document test results, and report bugs clearly and concisely.",
                "Team Player: Ability to work collaboratively in a team environment, share knowledge, and contribute to the overall success of the QA process and the software development lifecycle.",
                "Learning Attitude: Enthusiasm for continuous learning and staying updated with industry trends, emerging technologies, and best practices in software testing and quality assurance."
            ],
        })

        this.setState({ careers });
    }

    render() {
        return (
            <div className="section-container bg-light">
                <h2 className="section-title">משרות פנויות</h2>
                <h4 className="section-sub-title">
                </h4>

                <div className="careers-container">
                    <div className="box">
                        {this.state.careers.map((val, ind) => <div key={ind} className='job-container-item'>
                            <span onClick={() => this.setState({ selectedItem: val, modalShow: true })}>{val.id} - {val.jobDescription}</span>
                        </div>)}
                    </div>
                </div>


                <JobDescription show={this.state.modalShow} 
                    onHide={() => this.setState({modalShow: false})}
                    selectedItem={this.state.selectedItem}
                    />
            </div>
        );
    }
}