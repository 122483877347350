import React, { useEffect, useRef, useState } from 'react';
import Carsousel from "react-elastic-carousel";

const breakPoints = [
    {width: 1, itemsToShow: 1},
    {width: 550, itemsToShow: 2},
    {width: 768, itemsToShow: 3},
    {width: 1200, itemsToShow: 4}
];

const OutCustomers = () => {

    const [partners, setPartners] = useState([]);
    const [currentDisplayItemsNumber, setCurrentDisplayItemsNumber] = useState(1);

    const carouselRef = useRef();

    useEffect(() => {

        let arr = [];

        arr.push({img: require('../assets/images/logos/3dmail.png')});
        arr.push({img: require('../assets/images/logos/3dmail_courier.png')});
        arr.push({img: require('../assets/images/logos/888.png')});
        arr.push({img: require('../assets/images/logos/assest.png')});
        arr.push({img: require('../assets/images/logos/blirokratia.png')});
        arr.push({img: require('../assets/images/logos/ext.png')});
        arr.push({img: require('../assets/images/logos/bdi_logo.png')});
        arr.push({img: require('../assets/images/logos/bethatinok.png')});
        arr.push({img: require('../assets/images/logos/delivo_logo.png')});
        arr.push({img: require('../assets/images/logos/kahool_lavan.jpeg')});
        arr.push({img: require('../assets/images/logos/yanabea_logo.png')});
        arr.push({img: require('../assets/images/logos/waked.png')});
        arr.push({img: require('../assets/images/logos/nil.png')});
        arr.push({img: require('../assets/images/logos/unitec.png')});
        arr.push({img: require('../assets/images/logos/tofes4.png')});
        arr.push({img: require('../assets/images/logos/clalit.png')});

        setPartners(arr);
        updateCurrentDisplayItemsNumber();
        window.addEventListener('resize', updateCurrentDisplayItemsNumber);

        return () => {
            window.removeEventListener('resize', updateCurrentDisplayItemsNumber);
        }
    }, []);

    const updateCurrentDisplayItemsNumber = () => {
        let currWidth = window.innerWidth;
        let items = 1;

        if (currWidth >= 1200) {
            items = 4;
        } else if (currWidth >= 768) {
            items = 3;
        } else if (currWidth >= 550) {
            items = 2;
        }

        setCurrentDisplayItemsNumber(items);
    }

    const handleLastItemScrollEnd = (index) => {
        let lastItemIndex = Math.floor(partners.length / currentDisplayItemsNumber);

        if (index === lastItemIndex) {
            carouselRef.current.goTo(0);
        }
    }

    return (
        <div className="section-container bg-light">
            <h2 className="section-title">הלקוחות שלנו</h2>
            <h4 className="section-sub-title">אנחנו גאים להציג בפניכם חלק מהלקוחות שלנו</h4>

            <div className="portfolio-area" style={{marginTop: 80}}>
                <Carsousel breakPoints={breakPoints}
                    enableAutoPlay
                    enableSwipe
                    pagination={false}
                    autoPlaySpeed={3000}
                    ref={carouselRef}
                    onNextEnd={(_, index) => handleLastItemScrollEnd(index)}>
                    {
                        partners.map((val, ind) =>
                            <img key={ind} alt="" src={val.img} style={{objectFit: "contain", height: 100, width: "30%", aspectRatio: "3/2"}} />
                        )
                    }
                </Carsousel>
            </div>
        </div>
    )
}

export default OutCustomers;