import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import "../assets/styles/contact.css"
import { Constant } from '../config/constants';

export default class Contact extends Component{

    state = {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        errorMessage: "",
        successMessage: "",
        isLoading: false
    }

    submitForm = async () => {

        let {name, email, phone, subject, message} = this.getFields();

        this.setState({ errorMessage: "", successMessage: "", isLoading: true });

        if(name.length === 0){
            this.setState({ errorMessage: "חובה להזין את השם שלך" })
        } else if (email.length === 0) {
            this.setState({ errorMessage: "חובה להזין דואר אלקטרוני" })
        } else if (phone.length === 0) {
            this.setState({ errorMessage: "חובה להזין מספר טלפון" })
        } else if (subject.length === 0){
            this.setState({ errorMessage: "חובה להזין נושא הפניה" })
        } else if (message.length === 0){
            this.setState({ errorMessage: "חובה להזין הודעה" })
        } else if (!this.validateEmail(email)){
            this.setState({ errorMessage: "הדואר האלקטרוני שהזנת אינו תקין" })
        } else if (!this.validatePhone(phone)) {
            this.setState({ errorMessage: "מספר הטלפון שהזנת אינו תקין, מספר טלפון חייב להיות בן 10 ספרות ומתחיל ב- 05" })
        } else {
            //Everything is fine

            try{
                let url = Constant.API_URL + "/api/email";
                let params = {
                    name,
                    email,
                    phone,
                    subject,
                    message
                }

                let res = await fetch(url, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(params)
                });

                await res.json();
                //console.log("response: ", response)
            }catch(e){
                console.log(e);
            }

            this.setState({ successMessage: "הפניה שלך התקבלה בהצלחה", name: "", phone: "", email: "", subject: "", message: "" });

            setTimeout(() => {
                this.setState({ successMessage: "" });
            }, 2300);
        }

        this.setState({ isLoading: false });
    }

    getFields = () => {
        let {name, email, phone, subject, message} = this.state;

        if(name){
            name = name.trim();
        }

        if(email){
            email = email.trim();
        }

        if(phone){
            phone = phone.trim();
        }

        if(subject){
            subject = subject.trim();
        }

        if(message){
            message = message.trim();
        }

        return {
            name, email, phone, subject, message
        };
    }

    validateEmail = (email) => {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    validatePhone = (phone) => {
        let fixedPhone = "";
    
        for(let i = 0; i < phone.length; i++){
            if(phone.charAt(i) >= '0' && phone.charAt(i) <= '9'){
                fixedPhone += phone.charAt(i);
            }
        }
    
        return fixedPhone.length === 10 && fixedPhone.substr(0, 2) === '05';
    }

    render(){
        return (
            <div id="contact" className="section-container bg-light">
                <h2 className="section-title">צור איתנו קשר</h2>
                <h4 className="section-sub-title">אנחנו כאן בשבילכם, אל תהססו לדבר איתנו בכל עת</h4>

                <div className="contact-form-container">
                    <div className="contact-form">
                        <div className="form-area">
                            <div className="contact">
                                
                                <div className="map contact-info animated fadeInLeft">
                                    <div className="contact-info-item">
                                        <label className="contact-info-title">
                                            <i className="fas fa-map-marker"></i> 
                                            הכתובת שלנו 
                                        </label>
                                    </div>
                                    <div className="contact-info-item">
                                        <label className="contact-info-value">
                                            באקה אלגרבייה, רח' אבן חולדון
                                        </label>
                                    </div>

                                    <div className="contact-info-item">
                                        <label className="contact-info-title">
                                            <i className="fas fa-envelope"></i> 
                                            הדואר האלקטרוני שלנו
                                        </label>
                                    </div>
                                    <div className="contact-info-item">
                                        <label className="contact-info-value pointer"
                                            onClick={() => window.location.href = "mailto:info@infinitesol.co.il"}>
                                        info@infinitesol.co.il
                                        </label>
                                    </div>

                                    <div className="contact-info-item">
                                        <label className="contact-info-title">
                                            <i className="fas fa-mobile"></i> 
                                            הטלפון שלנו
                                        </label>
                                    </div>
                                    <div className="contact-info-item">
                                        <label className="contact-info-value pointer" onClick={() => window.location.href = "tel:0504844644"}>
                                        050-4844644
                                        </label>
                                    </div>
                                    
                                </div>

                                <form className="form animated fadeInUp">

                                    <label>
                                        <input type="text" name="name" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="subject text-right"/>
                                        <div className="label subject-text">מה שמך המלא?</div>
                                    </label>

                                    <label>
                                        <input type="email" name="email" className="email text-right" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}/>
                                        <div className="label email-text">מה הדוא"ל שלך?</div>
                                    </label>

                                    <label>
                                        <input type="text" name="phone" className="subject text-right" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })}/>
                                        <div className="label subject-text">מה מספר הטלפון שלך?</div>
                                    </label>

                                    <label>
                                        <input type="text" name="subject" className="subject text-right" value={this.state.subject} onChange={(e) => this.setState({ subject: e.target.value })}/>
                                        <div className="label subject-text">מה נושא הפניה?</div>
                                    </label>

                                    <label>
                                        <textarea rows="8" cols="80" className="help-box text-right" value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })}></textarea>
                                        <div className="label help-text">איך אנחנו יכולים לעזור לך?</div>
                                    </label>
                                    
                                    <div className="text-right"><label className="text-success">{this.state.successMessage}</label></div>
                                    <div className="text-right"><label className="text-danger">{this.state.errorMessage}</label></div>

                                    {this.state.isLoading ? 
                                        <div className="text-center" style={{margin: "0 auto"}}>
                                            <ReactLoading type="spin" color="rgb(41, 171, 226)" height={50} width={50} className="text-center m-auto" />
                                        </div>
                                        :
                                        <div className="submit-area">
                                            <input type="button" id="submit" className="submit-button" value="שלח עכשיו" onClick={this.submitForm} />
                                            <i className="far fa-paper-plane"></i>
                                        </div>
                                    }
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}