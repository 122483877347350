import React from 'react';

const NotFound = (props) => {
    return (
        <div className="app-main__outer">
            <div className="app-main__inner">
                <h2>Not Found</h2>
            </div>
        </div>
    );
}

export default NotFound;