import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'

const PortfolioModal = (props) => {

    let item = props.selectedItem;
    let {portfolioTypes} = props;

    if (!item){
        return null;
    }
    
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {item.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img src={item.image} alt="" className="modal-img" />
                
                <h4 className="mt-4">
                {item.type === portfolioTypes.WEB ? "אתר אינטרנט" : "אפליקציית מובייל"}
                </h4>

                <p className="mt-2 mb-3">
                    {item.description}
                </p>

                {item.type === portfolioTypes.WEB ? 
                    <div>
                        <a href={item.webURL}>{item.webURL}</a>
                    </div>
                    :
                    <div>
                        <a href={item.appleURL}><img alt="" src={require('../assets/images/app.png')} style={{height: 52}}/></a>
                        <a href={item.androidURL}><img alt="" src={require('../assets/images/googleplay.png')} style={{height: 80}}/></a>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>סגור</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PortfolioModal;