import React, { Component } from 'react';

export default class HeaderInfo extends Component {

    render() {
        return (
            <div className="header-info-container">
                <label>ברוכים הבאים ל- <label className="bold">אינפנט סול</label></label>

                {this.props.isHomeScreen && (
                    <>
                        <label>אנחנו כאן כדי לעזור לכם להפוך את העסק שלכם למותג</label>

                        <a className="btn-header" href="#contact">
                            אני מעוניין לשמוע עוד פרטים
                        </a>
                    </>
                )}

                {
                    this.props.careers && (
                        <>
                            <label>משרות פנויות</label>
                        </>
                    )
                }

                {
                    this.props.applyJob && (
                        <>
                            <label>{this.props.title}</label>
                        </>
                    )
                }

            </div>
        )
    }
}