import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import AppRouter from './components/AppRouter';

import "./assets/styles/index.css";

function App() {
  return (
    <Router>
      <AppRouter />
    </Router>
  );
}

export default App;
