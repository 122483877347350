import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { SCREEN_NAMES } from '../config/constants';

const JobDescription = (props) => {

    let item = props.selectedItem;

    if (!item){
        return null;
    }

    
    let { id, jobDescription, description, teamDesc ,resp, qual, skills } = item;


    const apply = () => {
        window.location.href = SCREEN_NAMES.APPLY_JOB_SCREEN + '/' + id;
    }
    
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {item.jobDescription}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className='careers-content'>
                <h3 className='job-title'>{jobDescription} - {id}</h3>

                <h4 className='job-item-title'>Job Description</h4>
                <p className='job-item-description'>{description}</p>

                <h4 className='job-item-title'>Responsibilities</h4>
                <ul className='details-ul'>
                    {resp.map((val, ind) => <li key={ind}>
                        <span>{val}</span>
                    </li>)}
                </ul>

                <h4 className='job-item-title'>Qualifications</h4>
                <ul className='details-ul'>
                    {qual.map((val, ind) => <li key={ind}>
                        <span>{val}</span>
                    </li>)}
                </ul>

                <h4 className='job-item-title'>Preferred Skills</h4>
                <ul className='details-ul'>
                    {skills.map((val, ind) => <li key={ind}>
                        <span>{val}</span>
                    </li>)}
                </ul>

                <br /><br />
                <p className='job-item-description'>{teamDesc}</p>
                <br /><br />

                <Button onClick={() => apply()} className="btn-dark">Apply Now</Button>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default JobDescription;