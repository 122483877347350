import React, { Component } from 'react';

import "../assets/styles/services.css";

export default class OurServices extends Component{

    state = {
        services: []
    }

    componentDidMount(){
        let {services} = this.state;

        services.push({
            id: 1,
            name: "אפלקציות מובייל",
            description: "אפליקציית מוֹבַּייל או יישום מוֹבַּייל הוא יישום מחשב המיועד לשימוש בטלפונים חכמים , מחשבי לוח ומכשירים ניידים מסוגים אחרים. יישומים אלה בדרך כלל זמינים דרך פלטפורמות להפצת יישומים, אשר לרוב מתופעלות על ידי החברות שבבעלותן מערכות הפעלה למכשירים ניידים, כדוגמת: App Store של חברת אפל, Google Play"
        });

        services.push({
            id: 2,
            name: "דפי נחיתה",
            description: "דף נחיתה זה הדף הראשון אליו “נוחת” הלקוח הפוטנציאלי, זהו דף אינטרנט פרסומי עם מלל מכירתי ועיצוב מותאם שמטרתו לייצר פניות מלקוחות חדשים."
        })

        services.push({
            id: 3,
            name: "מערכת CRM",
            description: "מערכת CRM, או מערכת לניהול קשרי לקוחות, היא תוכנת ניהול המאגדת את מכלול כלי הניהול הממוחשבים תחת מעטפת אחת ומציגה תמונה עדכנית ומלאה על הלקוח ופעילות העסק בכלל, בכל רגע נתון: תהליכי מכירה, ניהול ושימור לקוחות, ניהול פרוייקטים, משימות, מלאי, הנהלת חשבונות ועוד."
        })

        services.push({
            id: 4,
            name: "מערכות מתקדמות",
            description: "מערכת מידע היא תוכנה או אוסף של תוכנות המאפשרות לנהל מידע בצורה ממוחשבת. על מערכות מידע התואמות את צרכיו של המנהל המודרני לקיים שלוש תכונות: אמינות, מהירות וזמינות"
        })

        services.push({
            id: 5,
            name: "זהות מותג",
            description: "הזהות המותגית (Brand ID) היא מכלול המאפיינים של המותג שאמורים לשקף את ערכו וייחודו. זהות מותגית מורכבת משם, לוגו, מוצרים, עיצוב, חברה מייצרת, מחיר, סיסמה, ביצועים ואריזות."
        })

        services.push({
            id: 6,
            name: "שיווק באינטרנט",
            description: "שיווק באינטרנט הוא שימוש באינטרנט לצורך שיווק, מיתוג, פרסום וקידום מכירות של מוצרים, שירותים ורעיונות."
        })

        this.setState({ services });
    }

    render(){
        return (
            <div id="services" className="section-container bg-light">
                <h2 className="section-title">השירותים שלנו</h2>
                <h4 className="section-sub-title">
                קצת על השירותים שלנו
                </h4>

                <div className="services-container">
                {
                    this.state.services.map((val, ind) => 
                        <div key={ind} className="box">
                            <div className="icon">{val.id}</div>
                            <div className="content">
                                <h3>{val.name}</h3>
                                <p>{val.description}</p>
                            </div>
                        </div>
                    )
                }    
                </div>
            </div>
        );
    }
}